import { useFormContext } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import CircularProgress from '../../../components/common/CircularProgress';
import useRowsAdSets from '../hooks/useRowsAdSets';
import type { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import SuspenseErrorFallback from '../../../components/common/SuspenseErrorFallback';
import FormContainer from '../../../components/layout/FormContainer';
import TextField from '../../../components/common/TextField';
import type { GridColumnHeaderParams, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import type { MarketingProcess } from '../../../types/shared';
import { CAMPAIGN_NAME_FILTER } from '../constants';
import { AdSetStatusSwitcher, DuplicateStatusMenu } from './FacebookFormComponents';
import type { CampaignEntry } from '../api/getCampaigns';
import type { AdSetEntry } from '../api/getAdSets';

interface AdSet {
  id: string;
  name: string;
  ads: number;
  status: string;
  disabled?: boolean;
}

const AdSetsForm = () => {
  const { watch, setValue, trigger, formState: { errors } } = useFormContext();
  const selectedAdSetsRows = watch('selectedAdSets') as AdSetEntry[];
  const selectedAdSets = selectedAdSetsRows?.map(({ id }) => id) ?? [];
  const isAdSetsFieldError = errors.selectedAdSets?.message;
  const filterByName = watch('filterAdSetsByName') as string;
  const marketingProcess = watch('marketingProcess') as MarketingProcess;
  const isMarketingProcessInbox = CAMPAIGN_NAME_FILTER[marketingProcess].includes('inbox');

  const COLUMNS = [
    { field: 'name', headerName: 'Adset Name', width: 850 },
    { field: 'ads', headerName: '№ Ads' },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams<any>) => {
        if (!isMarketingProcessInbox) return <DuplicateStatusMenu formField={'selectedAdSets'} />;

        return params.colDef.headerName;
      },
      renderCell: (params: GridRenderCellParams) => {
        if (!isMarketingProcessInbox) return <AdSetStatusSwitcher cellParams={params} />;

        return (params.row as CampaignEntry).status;
      },
    },
  ];

  const { rows, isLoading, isPending, isError, error } = useRowsAdSets();

  const filteredRows = useMemo(() =>
    rows?.filter(r =>
      !filterByName ||
      r.name.toLowerCase().includes(filterByName.toLowerCase()) ||
      selectedAdSets.includes(r.id), // retain all previously selected adSets in a list
    ), [selectedAdSets, rows, filterByName]);

  const handleSelectionMode = useCallback(
    (selectedRows: GridRowSelectionModel) => {
      const newSelectedAdSets = rows?.filter(({ id }) => selectedRows?.includes(id)).map(c => ({
        ...c,
        status: selectedAdSetsRows?.find(s => s.id === c.id)?.status ?? c.status, // retain last updated status or return current value
      }));
      setValue('selectedAdSets', newSelectedAdSets);
    },
    [setValue, rows, selectedAdSetsRows],
  );

  useEffect(() => {
    void trigger('selectedAdSets');
  }, [trigger, selectedAdSetsRows]);

  if (isPending) {
    return <CircularProgress title={'Ad Sets are loading...'} />;
  }

  if (isError) {
    return (
      <SuspenseErrorFallback
        title={'Choose Ad Sets'}
        message={(error as {
          cause?: { error?: string }
        })?.cause?.error ?? 'An error occurred while fetching ad sets'} />
    );
  }

  if (!rows?.length) {
    return <Typography>No ad sets found.</Typography>;
  }

  return (
    <FormContainer
      title={'Choose Ad Sets'}
      error={isAdSetsFieldError as string}
      sx={{ flexDirection: 'column', gap: 2, width: '100%' }}>
      {!isPending && rows?.length && <>
        <TextField placeholder={'Search by name'} name={'filterAdSetsByName'} />
        <Box style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={filteredRows as GridRowsProp<AdSet>}
            columns={COLUMNS}
            checkboxSelection
            rowSelectionModel={selectedAdSets}
            loading={isLoading}
            onRowSelectionModelChange={handleSelectionMode}
            hideFooterPagination
            getRowId={(row) => row.id}
            isRowSelectable={(params) => !params.row.disabled}
            getRowClassName={(params) => params.row.disabled ? 'row-disabled' : ''}
            sx={t => ({ '.row-disabled': { color: t.palette.action.disabled } })}
          />
        </Box>
      </>}
    </FormContainer>

  );
};

export default AdSetsForm;