import * as yup from 'yup';

export const facebookSchema = yup.object().shape({
  app: yup.string().when('$currentStep', {
    is: (val) => val === 0,
    then: (schema) => schema.required('Can\'t be empty'),
    otherwise: (schema) => schema.notRequired(),
  }),
  platform: yup.string().when('$currentStep', {
    is: (val) => val === 0,
    then: (schema) => schema.required('Can\'t be empty'),
    otherwise: (schema) => schema.notRequired(),
  }),
  marketingProcess: yup.string().when('$currentStep', {
    is: (val) => val === 0,
    then: (schema) => schema.required('Can\'t be empty'),
    otherwise: (schema) => schema.notRequired(),
  }),
  team: yup.string(),
  adType: yup.string().when('$currentStep', {
    is: (val) => val === 4,
    then: (schema) => schema.required('Ad Type must be selected first'),
    otherwise: (schema) => schema.notRequired(),
  }),
  adAccounts: yup.array().when('$currentStep', {
    is: (val) => val === 1,
    then: (schema) =>
      schema.min(1, 'At least one has to be applied').required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  selectedCampaigns: yup.array().when('$currentStep', {
    is: (val) => val === 2,
    then: (schema) =>
      schema.min(1, 'At least one has to be applied').required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  selectedAdSets: yup.array().when('$currentStep', {
    is: (val) => val === 3,
    then: (schema) =>
      schema.min(1, 'At least one has to be applied').required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  selectedCreatives: yup.array().when(['marketingProcess', '$currentStep'], {
    is: (marketingProcess, val) => val === 4 && marketingProcess.includes('inbox'),
    then: (schema) => schema.min(1, 'At least one creative has to be applied').required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  callToActions: yup.string().when('$currentStep', {
    is: (val) => val === 5,
    then: (schema) => schema.required('Can\'t be empty'),
    otherwise: (schema) => schema.notRequired(),
  }),
  selectedConversionDomain: yup.string().when(['platform', '$currentStep'], {
    is: (platform, currentStep) => platform === 'WEB' && currentStep === 5,
    then: (schema) => schema.required('Can\'t be empty'),
    otherwise: (schema) => schema.notRequired(),
  }),
  selectedAdSettings: yup.object().optional(),
  hotjar: yup.bool(),
  conversionDomain: yup.string(),
  adSettings: yup.string(),
  adLabelSuffix: yup.string(),
  packsWithCreatives: yup.array().of(yup.object().shape({
    tag: yup.string().default(''),
    creatives: yup.array().of(yup.string()).required(),
  }))
    .when(['marketingProcess', '$currentStep'], {
      is: (val, currentStep) => ['testing', 'scaling'].includes(val) && currentStep === 4,
      then: (schema) => schema.min(1, 'At least one pack has to be created').required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  activePacksWithCreatives: yup.number().nullable(),
  activePackTag: yup.string().default(''),
  filterCampaignsByName: yup.string().default(''),
  filterAdSetsByName: yup.string().default(''),
});