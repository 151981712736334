import { useQuery } from '@tanstack/react-query';
import { apiClient } from '../../../lib/api/apiClient';
import { isAxiosError } from 'axios';
import queryKeys from './queryKeys';
import type { SynchronousQueryConfig } from '../../../lib/react-query';

// Define types for the parameters and response
interface GetCampaignsParams {
  facebookAccountId: string[];
  campaignNameFilter?: string;
}

export type RowStatus = 'ACTIVE' | 'PAUSED'

export interface CampaignEntry {
  id: string;
  name: string;
  status: RowStatus;
}

interface ErrorResponse {
  message: string;
}

export const getCampaigns = async ({ params }: {
  params: GetCampaignsParams
}, signal: AbortSignal): Promise<CampaignEntry[]> => {
  const { facebookAccountId, campaignNameFilter } = params;

  // Create an array of promises for each facebookAccountId
  const promises = facebookAccountId.map(id =>
    apiClient.get<CampaignEntry[]>(`/api/v1/facebook_manager/get_campaigns/${id}`, {
      params: { campaign_name_filter: campaignNameFilter },
      signal,
    }),
  );

  try {
    const responses = await Promise.all(promises);

    const isError = responses.some(r => isAxiosError(r));
    if (isError) {
      const errorResponses: ErrorResponse[] = responses.filter((response, index) => {
        const error = isAxiosError(response);
        if (error) {
          response.message = `An error occurred while fetching campaigns for account ${facebookAccountId[index]}`;
        }
        return error;
      }) as unknown as ErrorResponse[];

      // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
      return Promise.reject(errorResponses);
    }

    // Filter out successful responses and handle errors
    const successfulResponses = responses.filter(response => response?.status >= 200 && response?.status <= 400);
    // Return the combined data from successful responses
    return successfulResponses.flatMap(response => response.data);
  } catch (error) {
    // Handle any other unexpected errors
    console.error(error);
    throw error;
  }
};

interface UseCampaignsOptions {
  params: GetCampaignsParams;
  config?: SynchronousQueryConfig<typeof getCampaigns, CampaignEntry[]>;
}


export const useCampaigns = ({ params, config }: UseCampaignsOptions) => {
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getCampaigns({ params }, signal),
    queryKey: [
      ...queryKeys.campaigns,
      params.facebookAccountId,
      params.campaignNameFilter,
    ],
    retry: false,
  });
};