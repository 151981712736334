import { useFormContext } from 'react-hook-form';
import { useAdSets } from '../api/getAdSets';
import type { CampaignEntry } from '../api/getCampaigns';

const useRowsAdSets = () => {
  const { watch } = useFormContext();
  const selectedCampaignsRows = watch('selectedCampaigns') as CampaignEntry[];
  const selectedCampaigns = selectedCampaignsRows.map((campaign) => campaign.id);
  const platform = watch('platform') as string;

  const { data: rows, isSuccess, isLoading, isPending, isError, error } = useAdSets({
    params: { selectedCampaigns, platform },
  });

  return {
    rows,
    isSuccess,
    isLoading,
    isPending,
    isError,
    error,
  };
};

export default useRowsAdSets;