import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate } from 'react-router';
import Box from '@mui/material/Box';
import useCheckAuth from '../hooks/useCheckAuth';
import { hotjar } from 'react-hotjar';


interface AuthProvider {
  children: ReactNode;
}

const AuthProvider: FC<AuthProvider> = ({ children }) => {
  const isHotjar = hotjar.initialized();
  const { isAuthenticated, isLoading, isSuccess, isUserTracking, username, email } = useCheckAuth();

  useEffect(() => {
    if (isUserTracking)
      hotjar.initialize({ id: 5273642, sv: 7 });
  }, [isUserTracking]);

  useEffect(() => {
    if (isAuthenticated && isHotjar && isUserTracking) {
      hotjar.identify(username ?? '', { email: email ?? 'Email not specified by API' });
    }
  }, [isAuthenticated, username, email, isHotjar, isUserTracking]);

  if (isLoading) {
    return <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}><CircularProgress /></Box>;
  }

  if (isSuccess && !isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;

};
export default AuthProvider;
